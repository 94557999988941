import { GlobalState } from '@ikon-web/event-shared';
import { PluginApi } from '@ikon-web/space-types';
import { IkonBuiltinPlugins, IkonClientConfiguration } from '@ikon-web/utils';
import * as Sentry from '@sentry/browser';
import Two from 'two.js';
import { Text } from 'two.js/src/text';
import { AudioPlayerPlugin } from './audio-player/audio-player.plugin';
import { AudioRecorderPlugin } from './audio-recorder/audio-recorder.plugin';
import { SpeechToTextPlugin } from './audio-recorder/speech-to-text.plugin';
import { BlobRendererPlugin } from './blob-renderer/blob-renderer.plugin';
import { IkonCommand } from './ikon-command';
import { IkonStatus } from './ikon-status';
import { LandmarkRecorderPlugin } from './landmark-recorder/landmark-recorder.plugin';
import { ScenePlayer } from './scene-player/scene-player';
import { ScreenRecorderPlugin } from './screen-recorder/screen-recorder.plugin';
import { UiPlugin } from './ui/ui.plugin';
import { UiContainerModel } from './ui/ui.type';
import { ValueStatisticsReading } from './value-statistics';
import { VideoPlayerPlugin } from './video-player/video-player.plugin';

export class IkonController {
  public audioEnabled = false;
  public microphoneEnabled = false;
  public speechRecognitionEnabled = false;
  private readonly overlayCanvas?: HTMLCanvasElement;
  private readonly sceneCanvas: HTMLCanvasElement | undefined;
  private readonly videoElement?: HTMLVideoElement;
  private audioPlayerPlugin?: AudioPlayerPlugin;
  private audioRecorderPlugin?: AudioRecorderPlugin;
  private landmarkRecorderPlugin?: LandmarkRecorderPlugin;
  private screenRecorderPlugin?: ScreenRecorderPlugin;
  private speechToTextPlugin?: SpeechToTextPlugin;
  private blobRendererPlugin?: BlobRendererPlugin;
  private videoPlayerPlugin?: VideoPlayerPlugin;
  private scenePlayer?: ScenePlayer;
  private two?: Two;
  private bandwidthText?: Text;
  private readonly ikonWorker = new Worker(new URL('./ikon-worker.ts', import.meta.url), {
    type: 'module',
  });
  private isClosed = false;
  private isClosedExternally = false;
  private isBlockingAction = false;
  private startTime: number;

  private isTapping = false;
  private previousEvent: MouseEvent | undefined;
  private scale = 100;
  private onMouseDownBind = this.onMouseDown.bind(this);
  private onMouseUpBind = this.onMouseUp.bind(this);
  private onMouseMoveBind = this.onMouseMove.bind(this);
  private onWheelBind = this.onWheel.bind(this);

  constructor(
    private configuration: IkonClientConfiguration,
    private options: {
      user?: { id: string; name?: string; locale?: string };
      overlayCanvas?: HTMLCanvasElement;
      sceneCanvas?: HTMLCanvasElement;
      videoCanvas?: HTMLCanvasElement;
      videoElement?: HTMLVideoElement;
      uiContainerListener: (element: UiContainerModel) => void;
      uiContainerRemoveListener: (data: { id: string }) => void;
      uiTextListener: (element: { containerId: string; elementId: number; text: string }) => void;
      openUiListener: (data: { category: string }) => void;
      closeUiListener: (data: { category: string }) => void;
      clearUiListener: (data: { category: string }) => void;
      stateListener?: (state: GlobalState) => void;
      userActiveListener?: (userActive: { user: string; active: boolean }) => void;
      chatBlockingActionListener?: (active: boolean) => void;
      openRoomListener?: (code: string, prompt?: string) => void;
      reloadRoomsListener?: () => void;
      uiOpen?: (content: { name: string; elements: any[] }) => void;
      uiClose?: (content: { name: string }) => void;
      onLive?: () => void;
      onClose?: (error?: Error) => void;
    },
  ) {
    this.startTime = performance.now();
    console.log('[IkonController] Initialise');
    this.overlayCanvas = options.overlayCanvas;
    this.sceneCanvas = options.sceneCanvas;
    this.videoElement = options.videoElement;

    if (this.overlayCanvas) {
      this.configureOverlayStats();
    }

    this.ikonWorker.addEventListener('message', (event) => {
      const command: IkonCommand = event.data.command;

      switch (command) {
        case IkonCommand.Status:
          this.onStatus(event.data.data);
          break;
        case IkonCommand.State:
          if (this.options.stateListener) this.options.stateListener(event.data.data);
          break;
        case IkonCommand.Stats:
          this.renderStats(event.data.data);
          break;
        case IkonCommand.UiContainer:
          if (this.options.uiContainerListener) this.options.uiContainerListener(event.data.data);
          break;
        case IkonCommand.UiContainerRemove:
          if (this.options.uiContainerRemoveListener) this.options.uiContainerRemoveListener(event.data.data);
          break;
        case IkonCommand.UiText:
          if (this.options.uiTextListener) this.options.uiTextListener(event.data.data);
          break;
        case IkonCommand.UiOpen:
          if (this.options.openUiListener) this.options.openUiListener(event.data.data);
          break;
        case IkonCommand.UiClose:
          if (this.options.closeUiListener) this.options.closeUiListener(event.data.data);
          break;
        case IkonCommand.UiClear:
          if (this.options.clearUiListener) this.options.clearUiListener(event.data.data);
          break;
        case IkonCommand.UserActive:
          if (this.options.userActiveListener) this.options.userActiveListener(event.data.data);
          break;
        case IkonCommand.ChatBlockingAction:
          this.isBlockingAction = event.data.data;
          if (this.options.chatBlockingActionListener) this.options.chatBlockingActionListener(event.data.data);
          break;
        case IkonCommand.OpenExternalUrl:
          window.open(event.data.data.url, '_blank');
          break;
        case IkonCommand.OpenRoom:
          if (this.options.openRoomListener) this.options.openRoomListener(event.data.data.code, event.data.data.prompt);
          break;
        case IkonCommand.ReloadRooms:
          if (this.options.reloadRoomsListener) this.options.reloadRoomsListener();
          break;
        case IkonCommand.Closed:
          console.debug('[IkonController] Worker closed, terminating it');
          this.ikonWorker.terminate();
          break;
      }
    });

    this.ikonWorker.postMessage({
      command: IkonCommand.Configure,
      url: this.configuration.url,
      proxyUrl: this.configuration.proxyUrl,
      bucketViewUrl: this.configuration.bucketViewUrl,
      user: this.options.user,
      options: {
        wt: localStorage.getItem('ikon.wt') !== 'false',
        ws: localStorage.getItem('ikon.ws') !== 'false',
        wtp: localStorage.getItem('ikon.wtp') !== 'false',
        wsp: localStorage.getItem('ikon.wsp') !== 'false',
      },
    });

    this.ikonWorker.addEventListener('messageerror', (event) => {
      console.error('[IkonController] Worker message error', event);
    });

    this.ikonWorker.addEventListener('error', (event) => {
      console.error('[IkonController] Worker error', event);
      if (event.error) Sentry.captureException(event.error);
    });

    this.setupPlugins();

    window.addEventListener('visibilitychange', this.onVisibilityChange.bind(this));
    window.addEventListener('beforeunload', this.onBeforeUnload.bind(this));

    this.ikonWorker.postMessage({ command: IkonCommand.Connect });
  }

  sendText(text: string, complete: boolean) {
    this.ikonWorker.postMessage({ command: IkonCommand.SendChatText, text, complete });
  }

  sendFile(file: File) {
    this.ikonWorker.postMessage({ command: IkonCommand.SendChatFile, file });
  }

  callAction(id: string) {
    this.ikonWorker.postMessage({ command: IkonCommand.CallAction, id });
  }

  callFileUploadAction(action: string, file: File) {
    this.ikonWorker.postMessage({ command: IkonCommand.CallFileUploadAction, action, file });
  }

  setMicrophoneEnabled(enabled: boolean): void {
    this.microphoneEnabled = enabled;
    if (enabled) {
      this.ikonWorker.postMessage({ command: IkonCommand.StartAudioRecording });
      this.audioRecorderPlugin?.resume();
      if (this.speechRecognitionEnabled) this.speechToTextPlugin?.resume();
    } else {
      this.ikonWorker.postMessage({ command: IkonCommand.StopAudioRecording });
      this.speechRecognitionEnabled = false;
      this.audioRecorderPlugin?.pause();
      this.speechToTextPlugin?.pause();
    }
  }

  enableAudio(enabled: boolean) {
    if (this.audioPlayerPlugin) {
      enabled ? this.audioPlayerPlugin.resume() : this.audioPlayerPlugin.pause();
      this.audioEnabled = enabled;
    }
  }

  setSpeechRecognitionEnabled(enabled: boolean) {
    this.speechRecognitionEnabled = enabled;
    if (enabled) {
      this.speechToTextPlugin?.resume();
    } else {
      this.speechToTextPlugin?.pause();
    }
  }

  setScreenRecorder(capture: boolean): void {
    if (capture) {
      this.screenRecorderPlugin?.start();
    } else {
      this.screenRecorderPlugin?.close();
    }
  }

  resize(width: number, height: number) {
    if (this.two) {
      this.two.width = width;
      this.two.height = height;
      this.two.renderer.setSize(width, height);
    }

    this.ikonWorker.postMessage({ command: IkonCommand.Resize, width, height });
  }

  async close() {
    this.isClosedExternally = true;
    await this.closeInternal();
  }

  private async closeInternal() {
    if (this.isClosed) return;
    this.isClosed = true;

    await Promise.all([
      this.audioPlayerPlugin?.close(),
      this.audioRecorderPlugin?.close(),
      this.blobRendererPlugin?.close(),
      this.landmarkRecorderPlugin?.close(),
      this.screenRecorderPlugin?.close(),
      this.speechToTextPlugin?.close(),
      this.videoPlayerPlugin?.close(),
    ]);

    this.removeMouseEvents();
    window.removeEventListener('visibilitychange', this.onVisibilityChange);
    window.removeEventListener('beforeunload', this.onBeforeUnload);

    this.ikonWorker.postMessage({ command: IkonCommand.Close });
  }

  private setupPlugins() {
    new UiPlugin(this.ikonWorker);

    if (this.configuration.apis.includes(PluginApi.ClientAudioRenderer)) {
      this.audioPlayerPlugin = new AudioPlayerPlugin(this.ikonWorker);
    }

    if (this.configuration.apis.includes(PluginApi.ClientAudioRecorder)) {
      this.audioRecorderPlugin = new AudioRecorderPlugin(this.ikonWorker);
      if (this.microphoneEnabled) this.audioRecorderPlugin.resume();
    }

    if (this.configuration.builtinPlugins.includes(IkonBuiltinPlugins.SpeechToText)) {
      this.speechToTextPlugin = new SpeechToTextPlugin(this.ikonWorker);
      if (this.speechRecognitionEnabled) this.speechToTextPlugin.resume();
    }

    if (this.configuration.builtinPlugins.includes(IkonBuiltinPlugins.Landmarker) && this.videoElement) {
      this.landmarkRecorderPlugin = new LandmarkRecorderPlugin(this.ikonWorker, this.videoElement, 'face-landmarker', this.configuration.bucketViewUrl);
    }

    if (this.configuration.apis.includes(PluginApi.ClientBlobRenderer)) {
      if (this.options.videoCanvas) {
        this.blobRendererPlugin = new BlobRendererPlugin(this.ikonWorker, this.options.videoCanvas);
      } else {
        console.error('[IkonController] Failed to configure blob renderer plugin, canvas missing');
      }
    } else if (this.configuration.apis.includes(PluginApi.ClientVideoRenderer)) {
      if (this.options.videoCanvas) {
        this.videoPlayerPlugin = new VideoPlayerPlugin(this.ikonWorker, this.options.videoCanvas);
      } else {
        console.error('[IkonController] Failed to configure video player plugin, canvas missing');
      }
    }

    if (this.configuration.apis.includes(PluginApi.ClientScreenRecorder)) {
      this.screenRecorderPlugin = new ScreenRecorderPlugin(this.ikonWorker);
    }

    if (this.configuration.apis.includes(PluginApi.ClientSceneRenderer) && this.sceneCanvas) {
      this.scenePlayer = new ScenePlayer(this.ikonWorker, this.sceneCanvas, this.configuration.bucketViewUrl);
    }

    if (this.overlayCanvas) {
      this.setupMouseEvents();
    }
  }

  private onVisibilityChange() {
    this.ikonWorker.postMessage({ command: IkonCommand.Visibility, visible: !document.hidden });
  }

  private onBeforeUnload() {
    this.closeInternal();
  }

  private onStatus({ status, error }: { status: IkonStatus; error?: Error }) {
    if (status === IkonStatus.Live) {
      if (this.startTime) {
        const endTime = performance.now();
        console.debug(`[IkonController] Live in ${endTime - this.startTime}ms`);
      }
      if (this.options.onLive) this.options.onLive();
    }

    if (status === IkonStatus.Close) {
      this.closeInternal().finally(() => {
        if (this.options.onClose) {
          if (!this.isClosedExternally) this.options.onClose(error);
        }
      });
    }
  }

  private configureOverlayStats() {
    this.two = new Two({
      domElement: this.overlayCanvas,
    });

    this.bandwidthText = this.two.makeText('', 0, 0);
    this.bandwidthText.size = 14;
    this.bandwidthText.fill = '#fff';
    this.bandwidthText.alignment = 'left';

    const group = this.two.makeGroup(this.bandwidthText);
    group.position.set(20, 20);
  }

  private renderStats(stats: { dataIn: ValueStatisticsReading; dataOut: ValueStatisticsReading; audioBandwidth: number; sceneBandwidth: number; videoBandwidth: number }) {
    if (!this.two || !this.bandwidthText) return;

    const bandwidthIn = Math.ceil(stats.dataIn.average / 1000);
    const bandwidthOut = Math.ceil(stats.dataOut.average / 1000);
    const audioBandwidth = Math.ceil(stats.audioBandwidth / 1000);
    const sceneBandwidth = Math.ceil(stats.sceneBandwidth / 1000);
    const videoBandwidth = Math.ceil(stats.videoBandwidth / 1000);
    this.bandwidthText.value = `Bandwidth: In ${bandwidthIn}kB/s Out ${bandwidthOut}kB/s Audio ${audioBandwidth}kB/s Video ${videoBandwidth}kB/s Scene ${sceneBandwidth}kB/s`;
    this.two.update();
  }

  private setupMouseEvents() {
    console.debug('[IkonController] Setup mouse events');

    this.overlayCanvas?.addEventListener('mousedown', this.onMouseDownBind);
    this.overlayCanvas?.addEventListener('mouseup', this.onMouseUpBind);
    this.overlayCanvas?.addEventListener('mousemove', this.onMouseMoveBind);
    this.overlayCanvas?.addEventListener('wheel', this.onWheelBind);
  }

  private removeMouseEvents() {
    console.debug('[IkonController] Remove mouse events');

    this.overlayCanvas?.removeEventListener('mousedown', this.onMouseDownBind);
    this.overlayCanvas?.removeEventListener('mouseup', this.onMouseUpBind);
    this.overlayCanvas?.removeEventListener('mousemove', this.onMouseMoveBind);
    this.overlayCanvas?.removeEventListener('wheel', this.onWheelBind);
  }

  private onMouseDown(event: MouseEvent) {
    this.isTapping = true;
    this.previousEvent = event;
  }

  private onMouseUp(event: MouseEvent) {
    this.isTapping = false;

    if (this.previousEvent?.type === 'mousedown') {
      this.ikonWorker.postMessage({
        command: IkonCommand.SendTap,
        data: {
          StartInPixels: { X: event.x, Y: event.y },
          ScreenSizeInPixels: { X: this.overlayCanvas?.width, Y: this.overlayCanvas?.height },
        },
      });
    }

    this.previousEvent = event;
  }

  private onMouseMove(event: MouseEvent) {
    if (this.isTapping && this.previousEvent && (event.movementX > 0 || event.movementY > 0)) {
      this.ikonWorker.postMessage({
        command: IkonCommand.SendPan,
        data: {
          StartInPixels: { X: this.previousEvent.x, Y: this.previousEvent.y },
          DeltaInPixels: { X: event.movementX, Y: event.movementY },
          ScreenSizeInPixels: { X: this.overlayCanvas?.width, Y: this.overlayCanvas?.height },
        },
      });
    }

    this.previousEvent = event;
  }

  private onWheel(event: WheelEvent) {
    const originalScale = this.scale;
    this.scale = Math.max(0, this.scale + (event.deltaY > 0 ? 10 : -10));

    this.ikonWorker.postMessage({
      command: IkonCommand.SendZoom,
      data: {
        StartScale: originalScale / 100,
        CurrentScale: this.scale / 100,
      },
    });
  }
}
