import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { UiContainerElements } from './ui-container-elements';

export function UiContainerRight({ container, isCard, myMessage, disabled }: { container: any; isCard: boolean; myMessage: boolean; disabled?: boolean }) {
  return (
    <Box sx={{ display: 'inline-block', flexGrow: 1 }}>
      <Stack
        direction="row"
        spacing={container.gap || 0}
        useFlexGap
        sx={{
          alignItems: 'center',
          justifyContent: 'right',
        }}
      >
        <UiContainerElements container={container} isCard={isCard} myMessage={myMessage} disabled={disabled} />
      </Stack>
    </Box>
  );
}
