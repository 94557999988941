import { UIElementLabels } from '@ikon-web/event-shared';
import { UiElementText } from '@ikon-web/ikon-client';
import { Box, SxProps, Typography } from '@mui/material';
import { useMemo } from 'react';
import Markdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';
import remarkGfm from 'remark-gfm';

export function UiText({ element, myMessage, disabled }: { element: UiElementText; myMessage: boolean; disabled?: boolean }) {
  const sxProps = useMemo(() => {
    const props: SxProps = {};
    if (element.labels.includes(UIElementLabels.Blur)) {
      props['filter'] = 'blur(8px)';
    }

    return props;
  }, [element.labels]);

  if (!element.text) return null;

  if (element.labels.includes(UIElementLabels.ChatMessage)) {
    return (
      <Box
        sx={{
          padding: '12px',
          borderRadius: '14px',
          backgroundColor: myMessage ? 'surface.background.speechBubbleMy' : 'surface.background.speechBubbleOther',
          backdropFilter: 'blur(80px)',
          color: 'text.primary',
          lineHeight: '24px',
        }}
      >
        <Box
          sx={{
            '& a': { color: 'rgba(145, 150, 255, 1)', textDecoration: 'none' },
            '& > *:not(:last-child)': { mb: '.5rem' },
            '& h1': { m: '0' },
            '& h2': { m: '0' },
            '& h3': { m: '0' },
            '& p': { m: 0 },
            '& ul': { m: '0' },
            '& table': {
              borderSpacing: 0,
              borderCollapse: 'collapse',
              display: 'block',
              marginTop: 0,
              marginBottom: '16px',
              width: 'max-content',
              maxWidth: '100%',
              overflow: 'auto',
            },
            '& tr': {
              backgroundColor: '#fff',
              borderTop: '1px solid hsla(210, 18%, 87%, 1)',
              color: '#000',
            },
            '& tr:nth-of-type(even)': {
              backgroundColor: '#f6f8fa',
              color: '#000',
            },
            '& td': {
              padding: '6px 13px',
              border: '1px solid #d0d7de',
            },
            '& th': {
              padding: '6px 13px',
              border: '1px solid #d0d7de',
              fontWeight: 600,
            },
            '& table img': {
              backgroundColor: 'transparent',
            },
            '& pre': {
              whiteSpace: 'pre-wrap',
            },
          }}
        >
          <Markdown rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]} remarkPlugins={[remarkGfm]}>
            {element.text}
          </Markdown>
        </Box>
      </Box>
    );
  }

  if (element.type === 'strong')
    return (
      <Typography component="span" variant="body1" sx={{ display: 'block', fontWeight: 500, fontSize: '14px', color: disabled ? 'text.disabled' : 'text.primary', whiteSpace: 'pre-wrap', ...sxProps }}>
        {element.text}
      </Typography>
    );

  if (element.type === 'caption')
    return (
      <Typography
        component="span"
        variant="caption"
        sx={{ display: 'block', fontWeight: 400, fontSize: '10px', lineHeight: '120%', color: disabled ? 'text.disabled' : 'text.secondary', whiteSpace: 'pre-wrap', ...sxProps }}
      >
        {element.text}
      </Typography>
    );

  return (
    <Typography component="span" variant="caption" sx={{ display: 'block', fontSize: '14px', color: disabled ? 'text.disabled' : 'text.primary', whiteSpace: 'pre-wrap', ...sxProps }}>
      {element.text}
    </Typography>
  );
}
