import { UIIconType } from '@ikon-web/event-shared';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';
import PinDropIcon from '@mui/icons-material/PinDrop';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

export function UiIconType({ icon }: { icon: UIIconType }) {
  switch (icon) {
    case UIIconType.Close:
      return <CloseIcon fontSize="inherit" color="inherit" />;
    case UIIconType.Download:
      return <DownloadIcon fontSize="inherit" color="inherit" />;
    case UIIconType.Delete:
      return <DeleteIcon fontSize="inherit" color="inherit" />;
    case UIIconType.PinDrop:
      return <PinDropIcon fontSize="inherit" color="inherit" />;
    case UIIconType.Favorite:
      return <FavoriteIcon fontSize="inherit" color="inherit" />;
    case UIIconType.FavoriteBorder:
      return <FavoriteBorderIcon fontSize="inherit" color="inherit" />;
    case UIIconType.AddCircle:
      return <AddCircleIcon fontSize="inherit" color="inherit" />;
    case UIIconType.AddCircleOutline:
      return <AddCircleOutlineIcon fontSize="inherit" color="inherit" />;
    case UIIconType.StarOutline:
      return <StarOutlineIcon fontSize="inherit" color="inherit" />;
    case UIIconType.Document:
      return <DescriptionIcon fontSize="inherit" color="inherit" />;
    case UIIconType.GenderMale:
      return <MaleIcon fontSize="inherit" color="inherit" />;
    case UIIconType.GenderFemale:
      return <FemaleIcon fontSize="inherit" color="inherit" />;
    case UIIconType.Upload:
      return <BackupOutlinedIcon fontSize="inherit" color="inherit" />;
  }
}
