import { Box } from '@mui/material';
import { ChatContainers } from './chat-containers';
import { ChatInput } from './chat-input';

export function Chat({
  sendMessage,
  sendFile,
  setMicrophone,
  controlPosition,
  enableInput,
  enablePushToTalk,
}: {
  sendMessage: (text: string, isComplete: boolean) => void;
  sendFile: (file: File) => void;
  setMicrophone: (enabled: boolean) => void;
  controlPosition: string;
  enableInput: boolean;
  enablePushToTalk: boolean;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        height: '100%',
        maxWidth: '100%',
      }}
    >
      <ChatContainers alignEnd={enableInput} centerMessages={!enableInput} hasFade={enableInput} />

      {enableInput && (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', px: 2, scrollbarGutter: 'stable' }}>
          <Box
            sx={{
              p: 0,
              width: '100%',
              maxWidth: '700px',
              margin: controlPosition === 'right' ? '0 0 0 auto' : controlPosition === 'left' ? '0 auto 0 0' : '0 auto',
            }}
          >
            <ChatInput sendMessage={sendMessage} sendFile={sendFile} setMicrophone={setMicrophone} enablePushToTalk={enablePushToTalk} />
          </Box>
        </Box>
      )}
    </Box>
  );
}
