import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { UiContainer } from '../../components/ikon/ui-container';
import { selectSecondScreenContainer } from './second-screen-containers.slice';

export function SecondScreen() {
  const containerRef = useRef<HTMLDivElement>(null);
  const [previousContainerId, setPreviousContainerId] = useState<string | null>(null);
  const containerId = useSelector(selectSecondScreenContainer);

  useEffect(() => {
    if (containerId) {
      if (containerId !== previousContainerId) {
        containerRef.current?.scrollTo(0, 0);
      }
      setPreviousContainerId(containerId);
    }
  }, [containerId, previousContainerId]);

  if (!containerId) return null;

  return (
    <Box
      ref={containerRef}
      className="ikon-scrollbar"
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        p: 3,
        margin: 0,
        overflowX: 'hidden',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
      }}
    >
      <UiContainer containerId={containerId} myMessage={false} />
    </Box>
  );
}
