import { UiContainerModel } from '@ikon-web/ikon-client';
import { Box, CircularProgress } from '@mui/material';
import { ChangeEvent, useRef, useState } from 'react';
import { useIkon } from '../../shared/hooks/use-ikon';
import { UiContainerElements } from './ui-container-elements';

export function UiContainerFileUpload({ container, disabled }: { container: UiContainerModel; disabled?: boolean }) {
  const ikon = useIkon();

  const uploadInputRef = useRef(null);
  const [dragOver, setDragOver] = useState(false);
  const [uploading, setUploading] = useState(false);

  function handleFile(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    event.stopPropagation();

    if (event.target.files?.length) {
      processFile(event.target.files[0]);
    }
  }

  function handleDrop(event: any) {
    event.preventDefault();
    setDragOver(false);

    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length) {
      processFile(droppedFiles[0]);
    }
  }

  function processFile(file: File) {
    if (disabled) return;
    if (container.maxSize > 0 && file.size > container.maxSize) {
      console.log(`[Meet] File size is too large, size:${file.size}, max size:${container.maxSize}`);
      return;
    }

    setUploading(true);
    ikon.sendFileUploadAction(container.actionId, file);
  }

  return (
    <>
      {uploading ? (
        <CircularProgress />
      ) : (
        <Box
          onClick={() => uploadInputRef.current?.click()}
          onDrop={handleDrop}
          onDragOver={(event) => {
            event.preventDefault();
            if (disabled) return;

            setDragOver(true);
          }}
          onDragLeave={(event) => {
            event.preventDefault();
            if (disabled) return;

            setDragOver(false);
          }}
          sx={{
            border: '1px dashed #6E6F77',
            borderRadius: '10px',
            padding: '8px',
            backgroundColor: dragOver ? 'rgba(255, 255, 255, 0.10)' : 'transparent',
          }}
        >
          <UiContainerElements container={container} isCard={true} myMessage={false} disabled={disabled} />
        </Box>
      )}
      <Box
        component="input"
        type="file"
        accept={container.allowedMimeTypes?.join(', ') || '*/*'}
        onChange={(event) => handleFile(event)}
        ref={uploadInputRef}
        sx={{
          display: 'none',
        }}
      />
    </>
  );
}
