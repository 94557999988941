// Local storage
export const LOCAL_STORAGE_AUTH_REDIRECT = 'ikon.auth-redirect';
export const LOCAL_STORAGE_AUTH_USER = 'ikon.auth-user';
export const LOCAL_STORAGE_AUTH_DEVICE_ID = 'ikon.auth-device-id';
export const LOCAL_STORAGE_AUTH_TOKEN = 'ikon.auth-token';

export const LOCAL_STORAGE_DISCLAIMER_SHOWN = 'ikon.disclaimer-shown';

export const LOCAL_STORAGE_THEME = 'ikon.theme';
export const LOCAL_STORAGE_THEME_USER = 'ikon.theme-user';

// Environment variables
export const HAS_DEBUG_OVERLAY = import.meta.env.VITE_MEET_DEBUG_OVERLAY === 'true';
