import { useCallback } from 'react';
import { api } from '../../shared/api/api';
import { LOCAL_STORAGE_AUTH_TOKEN } from '../../shared/constants';
import { useAuth } from '../../shared/hooks/use-auth';
import { isIframe } from '../../shared/utils/iframe.utils';

export function useEmailLogin() {
  const auth = useAuth();
  const [authenticateEmail, { isLoading: isSendingEmail }] = api.useAuthenticateEmailMutation();
  const [authenticateEmailCode] = api.useAuthenticateEmailCodeMutation();

  const requestEmailLogin = useCallback(
    async (email: string) => {
      try {
        if (!email) return;
        await authenticateEmail({ email, useCode: isIframe }).unwrap();
      } catch (err) {
        console.log('[Meet] Failed to login with email', err);
      }
    },
    [authenticateEmail],
  );

  const sendEmailLoginCode = useCallback(
    async (code: string) => {
      const response = await authenticateEmailCode({ code }).unwrap();
      if (isIframe && response.token) localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, response.token);
      await auth.loadAuthentication();
    },
    [auth, authenticateEmailCode],
  );

  return {
    request: requestEmailLogin,
    requestLoading: isSendingEmail,
    sendEmailLoginCode,
  };
}
