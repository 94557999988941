import { Room } from '@ikon-web/space-types';
import { IkonClientConfiguration } from '@ikon-web/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../shared/data/store';
import { RoomUser } from './room-user';

const initialState: {
  room?: Room;
  users?: RoomUser[];
  isJoining: boolean;
  isJoined: boolean;
  isConnected: boolean;
  isIkonConnected: boolean;
  state?: string;
  configuration?: IkonClientConfiguration;
  error?: string;
} = {
  room: undefined,
  users: [],
  isJoining: false,
  isJoined: false,
  isConnected: false,
  isIkonConnected: false,
};

const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setRoom: (state, { payload: { room } }: PayloadAction<{ room: Room }>) => {
      if (state.room?.id === room.id) return;

      state.room = room;
      state.users = [];
      state.isJoining = false;
      state.isJoined = false;
      state.isConnected = false;
      state.isIkonConnected = false;
      state.state = undefined;
      state.configuration = undefined;
      state.error = undefined;
    },
    setJoiningRoom: (state) => {
      state.isJoining = true;
    },
    setJoinRoom: (state) => {
      state.isJoining = false;
      state.isJoined = true;
      state.error = undefined;
    },
    setConnectRoom: (state, { payload }: PayloadAction<{ state: string; configuration: IkonClientConfiguration }>) => {
      state.isConnected = true;
      state.state = payload.state;
      state.configuration = payload.configuration;
      state.error = undefined;
    },
    setIkonConnected: (state) => {
      state.isIkonConnected = true;
    },
    setRoomUsers: (state, { payload: { users } }: PayloadAction<{ users: any[] }>) => {
      state.users = users;
    },
    reset: () => initialState,
    setError: (state, { payload: { error } }: PayloadAction<{ error: any }>) => {
      state.error = error?.data?.message || error?.message || error;
    },
    resetError: (state) => {
      state.error = undefined;
    },
  },
});

export const roomReducer = roomSlice.reducer;

export const { setRoom, setJoiningRoom, setJoinRoom, setConnectRoom, setIkonConnected, setRoomUsers, reset: resetRoom, setError: setRoomError, resetError: resetRoomError } = roomSlice.actions;

export const selectCurrentRoomState = (state: RootState) => state.room;
export const selectRoom = (state: RootState) => state.room.room;
export const selectRoomUsers = (state: RootState) => state.room.users;
