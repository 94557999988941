import { UiElementButton } from '@ikon-web/ikon-client';
import { Button } from '@mui/material';
import { MouseEvent } from 'react';
import { useIkon } from '../../shared/hooks/use-ikon';
import { UiColorToString } from '../../shared/utils/protocol.utils';

export function UiButton({ element, disabled }: { element: UiElementButton; disabled?: boolean }) {
  const ikon = useIkon();

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    ikon.sendAction(element.action);
  }

  return (
    <Button variant="outlined" color={UiColorToString(element.color)} disabled={disabled} onClick={handleClick}>
      {element.text}
    </Button>
  );
}
