import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { UiContainer } from '../../components/ikon/ui-container';
import { selectContainerById } from '../../shared/data/container.slice';
import { RootState } from '../../shared/data/store';
import { selectAuthenticatedUserId } from '../auth/auth.slice';

export function ChatRootContainer({ containerId, centerMessages }: { containerId?: string; centerMessages?: boolean }) {
  const container = useSelector((state: RootState) => selectContainerById(state, containerId));
  const userId = useSelector(selectAuthenticatedUserId);

  const myMessage = container.userId === userId;

  if (!container) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: myMessage ? 'end' : 'start',
        marginLeft: !centerMessages && myMessage ? { xs: '16px', sm: '40px' } : null,
        marginRight: !centerMessages && !myMessage ? { xs: '16px', sm: '40px' } : null,
        overflowAnchor: 'none',
      }}
    >
      <UiContainer containerId={containerId} myMessage={myMessage} disabled={container.isHistory} />
    </Box>
  );
}
