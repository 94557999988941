import { UIStreamCategories } from '@ikon-web/event-shared';
import { IkonController } from '@ikon-web/ikon-client';
import { PluginApi, Room, SpacePublic } from '@ikon-web/space-types';
import { IkonBuiltinPlugins, IkonClientConfiguration } from '@ikon-web/utils';
import CallEndIcon from '@mui/icons-material/CallEnd';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import HearingIcon from '@mui/icons-material/Hearing';
import HearingDisabledIcon from '@mui/icons-material/HearingDisabled';
import MicNoneIcon from '@mui/icons-material/MicNone';
import MicOffIcon from '@mui/icons-material/MicOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PeopleIcon from '@mui/icons-material/People';
import TuneIcon from '@mui/icons-material/Tune';
import TvOffIcon from '@mui/icons-material/TvOff';
import { Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import { isBoolean } from 'lodash-es';
import { CSSProperties, MouseEvent, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useWakeLock } from 'react-screen-wake-lock';
import { api } from '../../shared/api/api';
import { HAS_DEBUG_OVERLAY } from '../../shared/constants';
import { IkonContext } from '../../shared/context/ikon.context';
import { clearChatContainers, receiveContainer, receiveText, removeContainer, resetContainer } from '../../shared/data/container.slice';
import { AppDispatch } from '../../shared/data/store';
import { User } from '../../shared/domain/user';
import { useInteraction } from '../../shared/hooks/use-interaction';
import { usePageVisibility } from '../../shared/hooks/use-page-visiblity';
import { useRoomStack } from '../../shared/hooks/use-room-stack';
import { useWindowSize } from '../../shared/hooks/use-window-size';
import { iframeMessages } from '../../shared/utils/iframe.utils';
import { Chat } from '../chat/chat';
import { clearChat, resetChat, setBlockingAction, setChatRoom, setChatUserTyping } from '../chat/chat.slice';
import { DebugOverlay } from '../debug-overlay/debug-overlay';
import { openDebugOverlay } from '../debug-overlay/debug-overlay.slice';
import { closeSecondScreen, openSecondScreen, receiveSecondScreenContainer, removeSecondScreenContainer, resetSecondScreenContainer } from '../second-screen/second-screen-containers.slice';
import { RoomUser } from './room-user';
import { setIkonConnected, setRoomUsers } from './room.slice';

export function RoomIkon(props: {
  configuration: IkonClientConfiguration;
  space: SpacePublic;
  room: Room;
  user: User;
  initialPrompt?: string;
  videoEnabled: boolean;
  setVideoEnabled: (state: boolean) => void;
  onLeave: (error?: Error) => void;
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { setHasInteraction } = useInteraction();
  const roomStack = useRoomStack();
  const ikonContext = useContext(IkonContext);
  const [ikonController, setIkonController] = useState<IkonController>();
  const [microphoneEnabled, setMicrophoneEnabled] = useState(false);
  const [speechRecognitionEnabled, setSpeechRecognitionEnabled] = useState(false);
  const [screenRecording, setScreenRecording] = useState(false);
  const [screenRecordingDisabled, setScreenRecordingDisabled] = useState(false);
  const [showDebug, setShowDebug] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [showUsersSelected, setShowUsersSelected] = useState(false);
  const [ikonUsers, setIkonUsers] = useState<RoomUser[]>([]);
  const [showMessages] = useState(true);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const overlayCanvasRef = useRef<HTMLCanvasElement>(null);
  const sceneCanvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef(null);
  const windowSize = useWindowSize();
  const wakeLock = useWakeLock();
  const pageVisible = usePageVisibility();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const showControls = props.space.layout === 'single';

  const [canvasWidth, setCanvasWidth] = useState(windowSize.width); //  - (props.space.layout === 'sidebar' ? 240 + 20 : 0); // sidenav + main content padding
  const [canvasHeight, setCanvasHeight] = useState(windowSize.height); // - (props.space.layout === 'sidebar' ? 48 + 64 + 10 : 0); // main nav + space nav + main content padding
  const [chatHeight, setChatHeight] = useState(windowSize.height - (props.space.layout === 'sidebar' ? 64 + 24 : 0));

  useEffect(() => {
    setCanvasWidth(windowSize.width);
    setCanvasHeight(windowSize.height);
    setChatHeight(windowSize.height - (props.space.layout === 'sidebar' ? 64 + 24 : 0));
  }, [props.space.layout, windowSize]);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const hasAudioRenderer = props.configuration.apis.includes(PluginApi.ClientAudioRenderer);
  const hasSceneRenderer = props.configuration.apis.includes(PluginApi.ClientSceneRenderer);
  const hasVideoRenderer = props.configuration.apis.includes(PluginApi.ClientVideoRenderer) || props.configuration.apis.includes(PluginApi.ClientBlobRenderer);
  const hasAudioRecorder = props.configuration.apis.includes(PluginApi.ClientAudioRecorder) || props.configuration.apis.includes(PluginApi.ClientAudioProcessor);
  const hasSpeechRecognition = props.configuration.builtinPlugins.includes(IkonBuiltinPlugins.SpeechToText);
  const hasScreenRecorder = props.configuration.apis.includes(PluginApi.ClientScreenRecorder);
  const hasChat = props.configuration.apis.includes(PluginApi.Chat);
  const hasChatInput = props.configuration.apis.includes(PluginApi.ChatInput);
  const hasVisualRenderer = hasSceneRenderer || hasVideoRenderer || hasChat;

  const controlPosition: any = 'center'; //props.space.layout === 'sidebar' && hasVideoRenderer ? 'left' : 'center';

  useEffect(() => {
    if (!showUsersSelected && windowSize.width < 800 && showUsers) setShowUsers(false);
  }, [showUsersSelected, showUsers, windowSize]);

  useEffect(() => {
    if (pageVisible && wakeLock.isSupported && wakeLock.released) wakeLock.request();
  }, [pageVisible, wakeLock]);

  useLayoutEffect(() => {
    if (!props.configuration.url) return null;

    roomStack.pushRoom(props.room.code);
    dispatch(setChatRoom({ room: props.room }));

    if (canvasRef.current) {
      canvasRef.current.width = canvasWidth;
      canvasRef.current.height = canvasHeight;
    }

    let users: RoomUser[] = [];

    console.log(`[Meet] Starting Ikon Controller`);
    const controller = new IkonController(props.configuration, {
      user: { ...props.user, locale: navigator.language },
      overlayCanvas: overlayCanvasRef.current as HTMLCanvasElement,
      sceneCanvas: sceneCanvasRef.current as HTMLCanvasElement,
      videoCanvas: canvasRef.current as HTMLCanvasElement,
      videoElement: videoRef.current as HTMLVideoElement,
      uiContainerListener: (e) => {
        dispatch(receiveContainer(e));
        if (e.category === UIStreamCategories.SecondScreen) {
          dispatch(receiveSecondScreenContainer(e));
        }

        console.debug('[Meet] UI container received', e);
      },
      uiContainerRemoveListener: ({ id }) => {
        dispatch(removeContainer(id));
        dispatch(removeSecondScreenContainer(id));
      },
      uiTextListener: (data) => {
        dispatch(receiveText(data));
      },
      openUiListener: (data) => {
        if (data.category === UIStreamCategories.SecondScreen) {
          dispatch(openSecondScreen());
        }
      },
      closeUiListener: (data) => {
        if (data.category === UIStreamCategories.SecondScreen) {
          dispatch(closeSecondScreen());
        }
      },
      clearUiListener: (data) => {
        if (data.category === UIStreamCategories.Chat) {
          dispatch(clearChatContainers());
          dispatch(clearChat());
        }

        if (data.category === UIStreamCategories.SecondScreen) {
          dispatch(resetSecondScreenContainer());
        }
      },
      stateListener: (state) => {
        props.setVideoEnabled(Object.keys(state.VideoStreams).length > 0);
        users = Object.values(state.Clients).map((userClient) => {
          const videoStream = Object.values(state.VideoStreams).find((stream) => stream.ClientSessionId === userClient.SessionId);
          return {
            id: userClient.UserId,
            sessionId: userClient.SessionId,
            isScreenSharing: !!videoStream,
          };
        });
        setIkonUsers(users);
        const screenCaptureVideoStream = Object.values(state.VideoStreams).find((stream) => stream.Info.Description === 'screen-capture');
        if (screenCaptureVideoStream) {
          const session = state.Clients[screenCaptureVideoStream.ClientSessionId];
          if (session) {
            if (session.UserId === props.user.id) {
              // Current user is streaming screen capture
              setScreenRecordingDisabled(false);
            } else {
              // Other user is streaming screen capture
              setScreenRecording(false);
              setScreenRecordingDisabled(true);
            }
          } else {
            console.debug('[Meet] Unknown video stream');
          }
        } else {
          setScreenRecording(false);
          setScreenRecordingDisabled(false);
        }

        dispatch(setRoomUsers({ users }));
      },
      userActiveListener: ({ user, active }) => {
        dispatch(setChatUserTyping({ user, active }));
      },
      chatBlockingActionListener: (active: boolean) => {
        dispatch(setBlockingAction({ active }));
      },
      openRoomListener: (code: string, prompt?: string) => {
        setHasInteraction();
        navigate(`/rooms/${code}${prompt ? `?prompt=${prompt}` : ''}`, { state: { interaction: true } });
      },
      reloadRoomsListener: () => {
        dispatch(api.endpoints.getRooms.initiate({ space: props.space.id }, { forceRefetch: true }));
      },
      onLive: () => {
        if (props.initialPrompt) {
          // Try giving some time for agent to initialize
          setTimeout(() => controller.sendText(props.initialPrompt, true), 200);
        }
        dispatch(setIkonConnected());
        iframeMessages({ type: 'live' });
      },
      onClose: (error) => {
        console.log('[Meet] Ikon Controller closed');
        props.onLeave(error);

        iframeMessages({ type: 'close' });
      },
    });
    setIkonController(controller);
    ikonContext.setIkonController(controller);

    setMicrophoneEnabled(controller.microphoneEnabled);
    setSpeechRecognitionEnabled(controller.speechRecognitionEnabled);

    if (wakeLock.isSupported && wakeLock.released) wakeLock.request();

    return () => {
      console.log('[Meet] Closing Ikon Controller');
      controller.close();
      if (!wakeLock.released) wakeLock.release();

      ikonContext.setIkonController();
      dispatch(resetChat());
      dispatch(resetContainer());
      dispatch(resetSecondScreenContainer());

      console.log('[Meet] Closing Ikon Controller done');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.configuration?.url]);

  useEffect(() => {
    if (ikonController) {
      ikonController.resize(canvasWidth, canvasHeight);
    }
  }, [ikonController, canvasWidth, canvasHeight]);

  const sendText = useCallback((message, complete) => ikonController.sendText(message, complete), [ikonController]);
  const sendFile = useCallback((file) => ikonController.sendFile(file), [ikonController]);

  useHotkeys('alt+s', () => dispatch(openDebugOverlay()), [dispatch]);

  function toggleMicrophone(value?: boolean) {
    const enabled = isBoolean(value) ? value : !microphoneEnabled;
    setMicrophoneEnabled(enabled);
    ikonController.setMicrophoneEnabled(enabled);
  }

  function toggleSpeechRecognition() {
    const enabled = !speechRecognitionEnabled;
    setSpeechRecognitionEnabled(enabled);
    ikonController.setSpeechRecognitionEnabled(enabled);
  }

  async function toggleScreenRecording() {
    const play = !screenRecording;
    setScreenRecording(play);
    ikonController.setScreenRecorder(play);
  }

  const canvasSize: CSSProperties = {
    position: 'absolute',
    inset: '0',
    overflowX: 'hidden',
  };

  return (
    <div>
      {hasVideoRenderer && (
        <>
          <video ref={videoRef} style={{ display: 'none' }}></video>
          <canvas
            ref={canvasRef}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100dvh',
              width: '100%',
              zIndex: -100,
            }}
          ></canvas>
        </>
      )}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: `${chatHeight}px`,
          overflowX: 'hidden',
          overflowY: 'hidden',
        }}
        ref={containerRef}
      >
        {hasSceneRenderer && <canvas ref={sceneCanvasRef} width={canvasWidth} height={canvasHeight} style={{ ...canvasSize, display: props.videoEnabled ? 'none' : 'block' }}></canvas>}

        {hasVisualRenderer ? (
          <>
            <canvas ref={overlayCanvasRef} width={canvasWidth} height={canvasHeight} style={{ ...canvasSize, display: showDebug ? 'block' : 'none' }}></canvas>

            {showUsers && ikonUsers?.length ? (
              <Box
                sx={{
                  position: 'absolute',
                  top: '0px',
                  right: '0px',
                }}
              >
                <Box
                  sx={{
                    color: '#fff',
                    fontSize: '14px',
                    padding: '10px',
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    backdropFilter: 'blur(5px)',
                  }}
                >
                  {ikonUsers.map((user) => {
                    return (
                      <div key={user.sessionId}>
                        <div>
                          {user.isScreenSharing ? <i className="bi bi-record-fill text-danger"></i> : null}
                          {user.name || user.id}
                        </div>
                      </div>
                    );
                  })}
                </Box>
              </Box>
            ) : null}

            {hasChat && showMessages ? (
              <Box
                sx={{
                  position: 'absolute',
                  top: '0px',
                  // height: '400px',
                  bottom: props.space.layout === 'sidebar' ? '0px' : '50px',
                  left: '0px',
                  right: '0px',
                  p: 0,
                }}
              >
                <Chat
                  sendMessage={sendText}
                  sendFile={sendFile}
                  setMicrophone={toggleMicrophone}
                  enableInput={hasChatInput}
                  enablePushToTalk={hasAudioRecorder}
                  controlPosition={controlPosition}
                ></Chat>
              </Box>
            ) : null}

            {showControls && (
              <div
                style={{
                  position: 'absolute',
                  bottom: '0px',
                  left: '0px',
                  right: '0px',
                }}
              >
                <Box sx={{ maxWidth: '1000px', width: '100%', margin: controlPosition === 'left' ? '0 auto 0 0' : '0 auto' }}>
                  <Stack direction="row" justifyContent="center" mb={1}>
                    {hasAudioRecorder ? (
                      <>
                        <Tooltip title={microphoneEnabled ? 'Mute microphone' : 'Enable microphone'}>
                          <IconButton size="large" aria-label="Toggle microphone" onClick={() => toggleMicrophone()} color="inherit">
                            {microphoneEnabled ? <MicNoneIcon /> : <MicOffIcon />}
                          </IconButton>
                        </Tooltip>

                        {hasSpeechRecognition ? (
                          <Tooltip title={speechRecognitionEnabled ? 'Close speech recording' : 'Enable speech recording'}>
                            <IconButton size="large" aria-label="Toggle microphone" onClick={() => toggleSpeechRecognition()} color="inherit">
                              {speechRecognitionEnabled ? <HearingIcon /> : <HearingDisabledIcon />}
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </>
                    ) : null}

                    {hasScreenRecorder ? (
                      <Tooltip title={screenRecordingDisabled ? 'Screen sharing already in use' : screenRecording ? 'Stop screen sharing' : 'Share screen'}>
                        <IconButton size="large" aria-label="Toggle screen sharing" onClick={() => toggleScreenRecording()} color="inherit">
                          {screenRecordingDisabled ? <TvOffIcon /> : <ConnectedTvIcon />}
                        </IconButton>
                      </Tooltip>
                    ) : null}

                    <Tooltip title="Settings">
                      <IconButton size="large" aria-label="Settings popup" aria-haspopup="true" onClick={handleMenu} color="inherit">
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="settings-menu"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => setShowDebug((value) => !value)}>
                        <TuneIcon sx={{ mr: 1 }} /> {showDebug ? 'Hide' : 'Show'} debug
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          const value = !showUsers;
                          setShowUsers(value);
                          setShowUsersSelected(value);
                        }}
                      >
                        <PeopleIcon sx={{ mr: 1 }} /> {showUsers ? 'Hide' : 'Show'} users
                      </MenuItem>
                    </Menu>

                    <Tooltip title="Leave room">
                      <IconButton size="large" aria-label="Leave room" onClick={() => props.onLeave()} color="inherit">
                        <CallEndIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Box>
              </div>
            )}
          </>
        ) : null}
      </Box>
      {HAS_DEBUG_OVERLAY && <DebugOverlay />}
    </div>
  );
}
