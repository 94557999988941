import { UIColor, UIIconType } from '@ikon-web/event-shared';

interface UiElementBase {
  labels?: string[];
}

export enum UiElementType {
  Header,
  Text,
  Badge,
  Image,
  Button,
  IconButton,
  File,
  Map,
  Separator,
  ListItem,
  Icon,
}

export type UiElementHeaderLevel = 'large' | 'medium' | 'normal' | 'small';

export interface UiElementHeader extends UiElementBase {
  text: string;
  subText: string;
  level: UiElementHeaderLevel;
}

export type UiElementTextType = 'normal' | 'strong' | 'caption';

export interface UiElementText extends UiElementBase {
  text: string;
  type: UiElementTextType;
}

export interface UiElementBadge {
  text: string;
  color: UIColor;
}

export interface UiElementImage extends UiElementBase {
  name: string;
  url: string;
  mime: string;
  actionId: string;
}

export interface UiElementButton {
  text: string;
  color: UIColor;
  action: string;
}

export interface UiElementIconButton {
  icon: UIIconType;
  action: string;
}

export interface UiElementIcon extends UiElementBase {
  icon: UIIconType;
  color: UIColor;
}

export interface UiElementListItem {
  name: string;
  text: string;
}

export interface UiElementFile {
  name: string;
}

export interface UiElementMapMarker {
  title: string;
  latitude: number;
  longitude: number;
}

export interface UiElementMap extends UiElementBase {
  name: string;
  marker: UiElementMapMarker;
  markers: UiElementMapMarker[];
}

export interface UiElementWrapper {
  type: UiElementType;
  element?: UiElementHeader | UiElementText | UiElementBadge | UiElementImage | UiElementButton | UiElementIconButton | UiElementIcon | UiElementFile | UiElementMap | UiElementListItem;
  elementId?: number;
}

export type UiContainerOrElement = UiElementWrapper | UiContainerModel;

export interface UiContainerModel {
  type: 'container' | 'card' | 'column' | 'row' | 'right' | 'file-upload' | 'ordered-list' | 'unordered-list' | 'definition-list' | 'carousel';
  elements: UiContainerOrElement[];
  gap?: number;
  parent?: UiContainerModel;
  labels?: string[];
  category?: string;
  containerId?: string;
  userId?: string;
  createdAt?: string;
  actionId?: string;
  allowedMimeTypes?: string[];
  maxSize?: number;
  isUpdate?: boolean;
  isHistory?: boolean;
}
