import { UIStreamCategories } from '@ikon-web/event-shared';
import { UiContainerModel, UiContainerOrElement } from '@ikon-web/ikon-client';
import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

const chatMessagesToKeep = 20;

const containerAdapter = createEntityAdapter({
  selectId: (message: UiContainerModel) => message.containerId,
});

const extraState: {
  chatContainers: { id: string; timestamp: string }[];
  chatContainerUpdatesCount: number;
  debugOverlayContainers: { id: string; timestamp: number }[];
} = { chatContainers: [], chatContainerUpdatesCount: 0, debugOverlayContainers: [] };

const removeContainerById = (state: RootState['container'], id: string) => {
  const removeContainer = (containerId: string) => {
    containerAdapter.removeOne(state, containerId);

    let index = state.chatContainers.findIndex((c) => c.id === containerId);
    if (index >= 0) state.chatContainers.splice(index, 1);

    index = state.debugOverlayContainers.findIndex((c) => c.id === containerId);
    if (index >= 0) state.debugOverlayContainers.splice(index, 1);
  };

  const processElements = (elements: UiContainerOrElement[]) => {
    for (const element of elements) {
      if ('elements' in element) processElements(element.elements);
      if (element.type === 'container') removeContainer(element.containerId);
    }
  };

  const container = state.entities[id];
  if (container) {
    removeContainer(id);
    processElements(container.elements);
  }
};

const containerSlice = createSlice({
  name: 'container',
  initialState: containerAdapter.getInitialState(extraState),
  reducers: {
    receiveContainer: (state, action: PayloadAction<UiContainerModel>) => {
      const processElements = (elements: UiContainerOrElement[]) => {
        for (const element of elements) {
          if (element.type === 'container') containerAdapter.setOne(state, element);
          if ('elements' in element) processElements(element.elements);
        }
      };

      if (action.payload.type !== 'container') return;
      // Updates where container is no longer in memory can be ignored
      if (action.payload.isUpdate && !state.ids.includes(action.payload.containerId)) return;

      containerAdapter.setOne(state, action.payload);
      processElements(action.payload.elements);

      if (action.payload.category === UIStreamCategories.Chat) {
        state.chatContainerUpdatesCount++;
        if (action.payload.isUpdate) return;

        if (state.chatContainers.some((c) => c.id === action.payload.containerId)) return;

        state.chatContainers.push({
          id: action.payload.containerId,
          timestamp: action.payload.createdAt,
        });
        state.chatContainers.sort((a, b) => {
          if (a.timestamp < b.timestamp) return -1;
          if (a.timestamp > b.timestamp) return 1;
          return 0;
        });

        // Keep only the last n messages
        while (state.chatContainers.length > chatMessagesToKeep) {
          const removedContainer = state.chatContainers.shift();
          removeContainerById(state, removedContainer.id);
        }
      }

      if (action.payload.category === UIStreamCategories.DebugOverlay) {
        if (action.payload.isUpdate) return;

        if (state.debugOverlayContainers.some((c) => c.id === action.payload.containerId)) return;

        state.debugOverlayContainers.push({
          id: action.payload.containerId,
          timestamp: (action.payload.createdAt ? new Date(action.payload.createdAt) : new Date()).getTime(),
        });
      }
    },
    removeContainer: (state, action: PayloadAction<string>) => {
      removeContainerById(state, action.payload);
    },
    receiveText: (state, action: PayloadAction<{ containerId: string; elementId: number; text: string }>) => {
      const container = state.entities[action.payload.containerId];
      if (!container) return;

      const element = container.elements.find((e) => e['elementId'] === action.payload.elementId);
      if (!element) return;

      (element as any).element.text = action.payload.text;
    },
    clearChatContainers: (state) => {
      const chatContainerIds = [...state.chatContainers];
      for (const chatContainerId of chatContainerIds) {
        removeContainerById(state, chatContainerId.id);
      }
    },
    reset: () => containerAdapter.getInitialState(extraState),
  },
});

export const containerReducer = containerSlice.reducer;

export const { receiveContainer, removeContainer, receiveText, clearChatContainers, reset: resetContainer } = containerSlice.actions;

export const { selectById: selectContainerById } = containerAdapter.getSelectors<RootState>((state) => state.container);

export const selectChatContainerIds = createSelector(
  (state: RootState) => state.container.chatContainers,
  (chatContainers) => {
    return chatContainers.map((c) => c.id);
  },
);

export const selectDebugOverlayContainerIds = createSelector(
  (state: RootState) => state.container.debugOverlayContainers,
  (debugOverlayContainers) => {
    return debugOverlayContainers.map((c) => c.id);
  },
);

export const selectChatContainerUpdatesCount = createSelector(
  (state: RootState) => state.container,
  (containerState) => containerState.chatContainerUpdatesCount,
);
