import { useEffect, useState } from 'react';
import { api } from '../../shared/api/api';

export function useConsumeInvitation(invitationId: string) {
  const [state, setState] = useState<'processing' | 'ok' | 'error'>('processing');
  const [error, setError] = useState<string | undefined>();
  const [consumeInvitation] = api.useConsumeInvitationMutation();

  useEffect(() => {
    const process = async () => {
      try {
        setState('processing');
        await consumeInvitation(invitationId).unwrap();
        setState('ok');
      } catch (err) {
        setState('error');
        setError(err.message);
      }
    };

    process();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationId]);

  return {
    state,
    error,
  };
}
