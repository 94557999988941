import { useContext } from 'react';
import { IkonContext } from '../context/ikon.context';

export function useIkon() {
  const ikonContext = useContext(IkonContext);

  return {
    sendAction: ikonContext.sendAction,
    sendFileUploadAction: ikonContext.sendFileUploadAction,
    enableAudio: ikonContext.enableAudio,
  };
}
