import { UIColor } from '@ikon-web/event-shared';

export function UiColorToString(color: UIColor) {
  switch (color) {
    case UIColor.Primary:
      return 'primary';
    case UIColor.Secondary:
      return 'secondary';
    case UIColor.Error:
      return 'error';
    case UIColor.Warning:
      return 'warning';
    case UIColor.Info:
      return 'info';
    case UIColor.Success:
      return 'success';
    default:
      return undefined;
  }
}
