import { IkonController } from '@ikon-web/ikon-client';
import { createContext } from 'react';

export interface IkonContextInterface {
  sendAction: (actionId: string) => void;
  sendFileUploadAction: (actionId: string, file: File) => void;
  setIkonController: (ikonController?: IkonController) => void;
  enableAudio: (enabled: boolean) => void;
}

const stub = (): never => {
  throw new Error('Ikon context provider missing');
};
export const initialContext = {
  sendAction: stub,
  sendFileUploadAction: stub,
  setIkonController: stub,
  enableAudio: stub,
};

export const IkonContext = createContext<IkonContextInterface>(initialContext);
