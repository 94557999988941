import { RoomTemplatePublic } from '@ikon-web/space-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { api } from '../../shared/api/api';
import { LOCAL_STORAGE_AUTH_REDIRECT } from '../../shared/constants';
import { useAuth } from '../../shared/hooks/use-auth';
import { useRooms } from '../../shared/hooks/use-rooms';
import { getDeviceId } from '../../shared/services/device-id';
import { ikonServerHost } from '../../shared/utils/env.utils';
import { SpaceSidebarLayout } from './layouts/sidebar/space-sidebar-layout';
import { SpaceSingleLayout } from './layouts/single/space-single-layout';
import { selectSpace } from './space.slice';

export function SpacePage() {
  const navigate = useNavigate();
  const { code } = useParams();
  const { user } = useAuth();
  const { rooms, mainRooms, isFetching } = useRooms();
  const space = useSelector(selectSpace);
  const [isProcessing, setIsProcessing] = useState(false);

  const { data: roomTemplates } = api.useGetRoomTemplatesBySpaceQuery(space.id);
  const [createRoom] = api.useCreateRoomMutation();

  useEffect(() => {
    if (!code && !isFetching) {
      if (mainRooms?.length) {
        console.log('[Meet] Opening main room', mainRooms[0].code);
        navigate(mainRooms[0].code);
      } else if (rooms.length) {
        console.log('[Meet] Opening first room', rooms[0].code);
        navigate(rooms[0].code);
      }
    }
  }, [navigate, code, mainRooms, rooms, isFetching]);

  if (user) {
    const redirect = localStorage.getItem(LOCAL_STORAGE_AUTH_REDIRECT);
    if (redirect) {
      localStorage.removeItem(LOCAL_STORAGE_AUTH_REDIRECT);
      return <Navigate to={redirect}></Navigate>;
    }
  }

  async function handleCreateRoom(roomTemplate: RoomTemplatePublic, local?: boolean) {
    try {
      setIsProcessing(true);
      const room = await createRoom({
        mode: local ? 'local' : undefined,
        roomTemplate: roomTemplate.id,
        ikonServerHost: ikonServerHost,
        deviceId: getDeviceId(user.id),
      }).unwrap();
      navigate(`/rooms/${room.code}`, { state: { createdRoom: true } });
    } catch (err) {
      console.log('[Meet] Failed to create room', err);
    } finally {
      setIsProcessing(false);
    }
  }

  if (space.layout === 'sidebar') return <SpaceSidebarLayout space={space} activeRoomCode={code} rooms={rooms} />;

  return <SpaceSingleLayout activeRoomCode={code} rooms={rooms} roomTemplates={roomTemplates?.results} isProcessing={isProcessing} onRoomCreate={handleCreateRoom} />;
}
