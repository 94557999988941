import { AnalyticsEvent } from '@ikon-web/event-shared';
import { useCallback, useEffect, useRef } from 'react';
import { api } from '../api/api';
import { useSpace } from './use-space';

export function useEvents() {
  const events = useRef<AnalyticsEvent[]>([]);
  const space = useSpace();
  const [sendEvent] = api.useEventMutation();

  useEffect(() => {
    if (space && events.current.length) {
      sendEvent({ event: { Events: events.current }, space: space.id });
      events.current = [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [space]);

  const sendAnalyticsEvent = useCallback(
    (eventName: string, message: string, parameters: Record<string, string> = {}) => {
      const event = { EventName: eventName, Message: message, Parameters: JSON.stringify(parameters), Time: new Date().toISOString() };
      if (space) {
        sendEvent({ event: { Events: [event] }, space: space.id });
      } else {
        events.current.push(event);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [space],
  );

  return {
    sendAnalytics: sendAnalyticsEvent,
  };
}
