import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import { selectContainerById } from '../../shared/data/container.slice';
import { RootState } from '../../shared/data/store';
import { UiContainerElements } from './ui-container-elements';
import { UiElement } from './ui-element';

export function UiContainer({ containerId, myMessage, disabled }: { containerId: string; myMessage: boolean; disabled?: boolean }) {
  const container = useSelector((state: RootState) => selectContainerById(state, containerId));

  if (!container) return null;

  if (container.elements.length === 0) return null;
  if (container.elements.length === 1) {
    const element = container.elements.at(0);
    return <UiElement element={element} myMessage={myMessage} disabled={disabled} />;
  }

  return (
    <Stack
      direction="column"
      spacing={1}
      useFlexGap
      className="ikon-container"
      sx={{
        width: '100%',
      }}
    >
      <UiContainerElements container={container} myMessage={myMessage} disabled={disabled} />
    </Stack>
  );
}
