import { ConnectionFailedErrorName } from '@ikon-web/ikon-client';
import { RoomMode } from '@ikon-web/space-types';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { api } from '../../shared/api/api';
import { selectEmbeddedSpace } from '../../shared/data/embedded-space.slice';
import { AppDispatch } from '../../shared/data/store';
import { useAuth } from '../../shared/hooks/use-auth';
import { useConnectRoom } from '../../shared/hooks/use-connect-room';
import { useInteraction } from '../../shared/hooks/use-interaction';
import { useSpace } from '../../shared/hooks/use-space';
import { getDeviceId } from '../../shared/services/device-id';
import { RoomError } from './room-error';
import { RoomIkon } from './room-ikon';

// const interactionRequiredApis = [PluginApi.ClientAudioRecorder];

export function RoomPage() {
  const { code } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const { user } = useAuth();
  const embeddedSpaceId = useSelector(selectEmbeddedSpace);
  const { hasInteraction, setHasInteraction } = useInteraction();
  const [videoEnabled, setVideoEnabled] = useState(false);
  const [initialPrompt, setInitialPrompt] = useState<string>(null);
  const [roomError, setRoomError] = useState<{ error?: Error; validateUrl?: string } | null>(null);

  const space = useSpace();
  const { room, configuration, isConnected, isIkonConnected, error: connectError } = useConnectRoom(code, getDeviceId(user.id), embeddedSpaceId, searchParams.has('prompt') || !!initialPrompt);

  useEffect(() => {
    // Reset initial prompt when room changes
    setInitialPrompt(null);
    setRoomError(null);
  }, [code]);

  useEffect(() => {
    if (location.state?.interaction && !hasInteraction) setHasInteraction();
  }, [hasInteraction, location, setHasInteraction]);

  useEffect(() => {
    if (searchParams.has('prompt')) {
      setInitialPrompt(searchParams.get('prompt'));
      navigate(location.pathname, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  function handleLeave(error?: Error) {
    dispatch(api.endpoints.getRooms.initiate({ space: space.id }, { forceRefetch: true }));
    if (error.name === ConnectionFailedErrorName) {
      const state: any = { error: error };
      if (configuration.url.includes('127.0.0.1')) {
        state.validateUrl = configuration.url.replace('/connect', '/validate');
      }
      setRoomError(state);
    } else if ([RoomMode.Local, RoomMode.LocalProxy].includes(room.mode as RoomMode)) {
      setRoomError({ error: new Error('Connection to the local server lost') });
    } else {
      navigate('/rooms');
    }
  }

  if (roomError) {
    return <RoomError space={space!} room={room!} roomError={roomError} />;
  }

  if (connectError) {
    return (
      <>
        <Typography textAlign="center" mt={5}>
          Room not found, maybe it has expired?
        </Typography>

        <Typography textAlign="center" mt={2}>
          <Button component={RouterLink} to="/rooms">
            Go back
          </Button>
        </Typography>
      </>
    );
  }

  return (
    <>
      {!isConnected && !isIkonConnected && (
        <Box textAlign="center" mt={5}>
          <CircularProgress />
        </Box>
      )}

      {isConnected && (
        <RoomIkon
          configuration={configuration}
          space={space}
          room={room}
          user={user}
          initialPrompt={initialPrompt}
          videoEnabled={videoEnabled}
          setVideoEnabled={(enabled) => {
            setVideoEnabled(enabled);
          }}
          onLeave={handleLeave}
        ></RoomIkon>
      )}
    </>
  );
}
