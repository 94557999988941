import { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectEmbeddedSpaceState } from '../data/embedded-space.slice';
import { useEvents } from '../hooks/use-events';
import { usePageVisibility } from '../hooks/use-page-visiblity';
import { AnalyticsContext } from './analytics.context';

export function AnalyticsProvider(opts: { children?: ReactNode }) {
  const events = useEvents();
  const embeddedSpaceState = useSelector(selectEmbeddedSpaceState);

  usePageVisibility((isVisible) => {
    if (isVisible) {
      console.debug('[Analytics] Web client visible');
      events.sendAnalytics('webclient_visible', '');
    } else {
      console.debug('[Analytics] Web client hidden');
      events.sendAnalytics('webclient_hidden', '');
    }
  });

  useEffect(() => {
    if (embeddedSpaceState.loaded) {
      console.debug('[Analytics] Web client loaded');
      const params: Record<string, string> = {
        host: embeddedSpaceState.id ? embeddedSpaceState.host : window.location.host,
      };
      if (embeddedSpaceState.id) params.embeddedSpace = embeddedSpaceState.id;

      events.sendAnalytics('webclient_loaded', '', params);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embeddedSpaceState.loaded]);

  return <AnalyticsContext.Provider value={{}}>{opts.children}</AnalyticsContext.Provider>;
}
