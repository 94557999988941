import Stack from '@mui/material/Stack';
import { UiContainerElements } from './ui-container-elements';

export function UiContainerRow({ container, isCard, myMessage, disabled }: { container: any; isCard: boolean; myMessage: boolean; disabled?: boolean }) {
  return (
    <Stack
      direction="row"
      spacing={container.gap || 0}
      useFlexGap
      sx={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'left',
      }}
    >
      <UiContainerElements container={container} isCard={isCard} myMessage={myMessage} disabled={disabled} />
    </Stack>
  );
}
