import { Room, SpacePublic } from '@ikon-web/space-types';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Navigation } from '../../../../components/navigation';
import { useAuth } from '../../../../shared/hooks/use-auth';
import { SecondScreen } from '../../../second-screen/second-screen';
import { selectSecondScreenOpen } from '../../../second-screen/second-screen-containers.slice';
import { SpaceSidebarDesktop } from './space-sidebar-desktop';
import { SpaceSidebarMobile } from './space-sidebar-mobile';

export function SpaceSidebarLayout(props: { space: SpacePublic; activeRoomCode: string; rooms: Room[] }) {
  const { user } = useAuth();
  const isSecondScreenOpen = useSelector(selectSecondScreenOpen);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Stack direction="row">
        <Box
          sx={{
            flex: '1 0 0',
            display: { xs: isSecondScreenOpen ? 'none' : 'block', md: 'block' },
          }}
        >
          <Stack
            direction="column"
            sx={{
              height: '100dvh',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              flexShrink: 0,
              alignSelf: 'stretch',
            }}
          >
            <Navigation />

            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                alignSelf: 'stretch',
                flex: '1 0 0',
                mb: 3,
              }}
            >
              {!isMobile && (
                <Box sx={{ position: 'relative', height: '100%' }}>
                  <SpaceSidebarDesktop space={props.space} rooms={props.rooms} activeRoomCode={props.activeRoomCode} user={user} />
                </Box>
              )}

              <Box
                sx={{
                  flex: '1 0 0',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-start',
                  alignSelf: 'stretch',
                }}
              >
                <Outlet />
              </Box>
            </Stack>
          </Stack>
        </Box>

        {isSecondScreenOpen && (
          <Box
            sx={{
              width: { xs: '100vw', md: '480px' },
              height: '100dvh',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                borderLeft: { xs: 'none', md: '1px solid rgba(255, 255, 255, 0.20)' },
                backdropFilter: 'blur(8px)',
              }}
            >
              <SecondScreen />
            </Box>
          </Box>
        )}
      </Stack>

      {isMobile && <SpaceSidebarMobile space={props.space} rooms={props.rooms} activeRoomCode={props.activeRoomCode} user={user} />}
    </>
  );
}
