import { UiContainerModel } from '@ikon-web/ikon-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../shared/data/store';

const initialState: { open: boolean; container?: string } = { open: false };

const secondScreenContainerSlice = createSlice({
  name: 'secondScreenContainer',
  initialState,
  reducers: {
    open: (state) => {
      state.open = true;
    },
    close: (state) => {
      state.open = false;
    },
    receiveContainer: (state, action: PayloadAction<UiContainerModel>) => {
      state.container = action.payload.containerId;
    },
    removeContainer: (state, action: PayloadAction<string>) => {
      if (state.container === action.payload) state.container = undefined;
    },
    reset: () => initialState,
  },
});

export const secondScreenContainerReducer = secondScreenContainerSlice.reducer;

export const {
  open: openSecondScreen,
  close: closeSecondScreen,
  receiveContainer: receiveSecondScreenContainer,
  removeContainer: removeSecondScreenContainer,
  reset: resetSecondScreenContainer,
} = secondScreenContainerSlice.actions;

export const selectSecondScreenOpen = (state: RootState) => state.secondScreenContainer.open;
export const selectSecondScreenContainer = (state: RootState) => state.secondScreenContainer.container;
