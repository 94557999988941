import { EmbeddedAsset } from '@ikon-web/assets-types';
import { ProviderType } from './provider-type';
import { SpaceTheme } from './space-theme';

export interface SpacePublic {
  id: string;
  name: string;
  slug: string;
  customisation: {
    name?: string;
    description?: string;
    disclaimer?: string;
    supportEmail?: string;
  };
  languages: {
    defaultLanguage: string;
    availableLanguages: string[];
  };
  layout: string;
  theme?: SpaceTheme;
  allowAnonymousUsers?: boolean;
  assets: EmbeddedAsset[];
}

export interface SalesforceProfileRecordType {
  recordTypeId?: string;
}

export interface SalesforceFieldMapping {
  field: string;
  externalInputFields: string[];
  externalOutputFields: string[];
}

export interface SpaceIntegrationSalesforce {
  enabled: boolean;
  instanceUrl?: string;
  username?: string;
  password?: string;
  clientId?: string;
  clientSecret?: string;
  leadFieldMappings?: SalesforceFieldMapping[];
  profileFieldMappings?: SalesforceFieldMapping[];
  profileRecordTypes?: {
    user?: SalesforceProfileRecordType;
    providerUser?: SalesforceProfileRecordType;
  };
  leadRecordType?: string;
  enableCreateProfile?: boolean;
}

export interface Space {
  id: string;
  organisation: string;
  domain: string;
  name: string;
  slug: string;
  customisation: {
    name?: string;
    description?: string;
    disclaimer?: string;
    supportEmail?: string;
  };
  languages: {
    defaultLanguage: string;
    availableLanguages: string[];
    useUserLocale: boolean;
  };
  layout: string;
  theme?: SpaceTheme;
  allowAnonymousUsers?: boolean;
  assets: EmbeddedAsset[];
  scene?: string;
  buckets?: string[];
  invitation: {
    invitationRequired?: boolean;
    inviteImported?: boolean;
    welcomeText?: string;
    infoText?: string;
    providers: {
      type?: ProviderType;
      infoText?: string;
    }[];
  };
  integrations: {
    salesforce: SpaceIntegrationSalesforce;
  };
  createdAt: string;
  updatedAt: string;
}

export function getSpaceLogo(space: SpacePublic | Space): EmbeddedAsset | undefined {
  return space.assets.find((a) => a.type === 'space-logo');
}
