import { UiContainerModel } from '@ikon-web/ikon-client';
import { Box } from '@mui/material';
import { useIkon } from '../../shared/hooks/use-ikon';
import { UiContainerElements } from './ui-container-elements';

export function UiContainerCard({ container, myMessage, disabled }: { container: UiContainerModel; myMessage: boolean; disabled?: boolean }) {
  const ikon = useIkon();

  return (
    <Box
      onClick={() => ikon.sendAction(container.actionId)}
      sx={(theme) => ({
        width: container.labels?.includes('size-fit-content') ? 'fit-content' : '100%',
        padding: '12px',
        borderRadius: '14px',
        backgroundColor: theme.palette.surface.background.speechBubbleOther,
        backdropFilter: 'blur(48px)',
        '&:hover': container.actionId
          ? {
              backgroundColor: disabled ? null : 'rgba(0, 0, 0, 0.10)',
            }
          : null,
        transition: 'background-color 0.2s ease-in-out',
        cursor: container.actionId && !disabled ? 'pointer' : 'default',
      })}
    >
      <UiContainerElements container={container} isCard={true} myMessage={myMessage} disabled={disabled} />
    </Box>
  );
}
