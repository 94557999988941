import { Box, Typography } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { api } from '../../shared/api/api';
import { useSpace } from '../../shared/hooks/use-space';

export function InvitationRequiredPage() {
  const space = useSpace();
  const { data: profileResponse, isLoading: profileLoading, error: profileError } = api.useGetProfileByMeQuery({ space: space?.id }, { skip: !space, refetchOnMountOrArgChange: true });

  if (!profileLoading && !profileError && profileResponse?.id) {
    // User has access, take back to rooms
    return <Navigate to="/rooms" />;
  }

  return (
    <Box sx={{ p: 2, maxWidth: '512px', ml: 'auto', mr: 'auto', textAlign: 'center' }}>
      <Typography variant="h1" mt={5} sx={{ fontSize: '48px' }}>
        {space?.customisation?.name ?? 'Ikon'}
      </Typography>

      <Typography variant="h5" component="h2" mt={2} sx={{ fontSize: '16px', color: '#9196FF' }}>
        Powered by IkonAI
      </Typography>

      <Typography variant="body1" sx={{ mt: 5, fontSize: '16px' }}>
        Currently we are requiring an invitation to join this space. Please check your email for an invitation link.
      </Typography>
    </Box>
  );
}
