import { TrackingFrame, TrackingStreamBegin, TrackingType } from '@ikon-web/event-shared';
import { Classifications, FaceLandmarker, FaceLandmarkerResult, FilesetResolver } from '@mediapipe/tasks-vision';
import * as Sentry from '@sentry/react';
import { IkonCommand } from '../ikon-command';
import { IkonStatus } from '../ikon-status';
import { Plugin } from '../plugin';

export class LandmarkRecorderPlugin implements Plugin {
  private isReady = false;
  private isRunning = false;
  private infoSent = false;
  private landmarker?: FaceLandmarker;
  private mediaStream?: MediaStream;
  private durationInUs = 16.67 * 1000;

  constructor(
    private ikonWorker: Worker,
    private videoElement: HTMLVideoElement,
    private useSolution: 'face-landmarker',
    private bucketViewUrl?: string,
  ) {
    console.debug('[LandmarkRecorderPlugin] Initialise');

    ikonWorker.addEventListener('message', this.onEvent.bind(this));
  }

  async close() {
    console.debug('[LandmarkRecorderPlugin] Close');
    this.videoElement.pause();
    this.mediaStream?.getVideoTracks().forEach((track) => track.stop());
    this.mediaStream = undefined;
  }

  start() {
    this.isRunning = true;
    this.videoElement.play();
  }

  stop() {
    this.isRunning = false;
    this.videoElement.pause();
  }

  private onEvent(event: MessageEvent) {
    const command: IkonCommand = event.data.command;

    if (command === IkonCommand.Status) {
      const { status } = event.data.data;
      if (status === IkonStatus.Live) {
        this.setup().catch((err) => {
          Sentry.captureException(err);
          console.error('[LandmarkRecorderPlugin] Initialise failed', err);
        });
      }
    }
  }

  private async setup() {
    if (this.useSolution === 'face-landmarker') {
      if (!this.bucketViewUrl) {
        console.log('[LandmarkRecorderPlugin] Bucket view not defined, unable to configure');
        return;
      }

      const vision = await FilesetResolver.forVisionTasks('tasks-vision');
      this.landmarker = await FaceLandmarker.createFromOptions(vision, {
        baseOptions: {
          modelAssetPath: `${this.bucketViewUrl}/face_landmarker_v2_with_blendshapes.task`,
        },
        runningMode: 'VIDEO',
        numFaces: 1,
        outputFaceBlendshapes: true,
        outputFacialTransformationMatrixes: true,
      });
    }

    await this.setupVideoCapture();
  }

  private async setupVideoCapture() {
    try {
      // navigator.mediaDevices.enumerateDevices().then((devices) => {
      //   devices.forEach(function (device) {
      //     console.log(`Video device ${device.label}, ID=${device.deviceId}`);
      //   });
      // });
      this.mediaStream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: 1280,
          height: 720,
          frameRate: { ideal: 60 },
          facingMode: 'user',
        },
      });
      this.videoElement.srcObject = this.mediaStream;
      this.videoElement.onloadedmetadata = () => {
        this.isReady = true;
        this.start();
      };

      console.log('[LandmarkRecorderPlugin] Configured video capture, starting landmark processing');

      const onFrame = async () => {
        if (this.isRunning && this.useSolution === 'face-landmarker' && this.landmarker) {
          this.onFaceLandmarkerResults(this.landmarker.detectForVideo(this.videoElement, performance.now()));
        }

        this.videoElement.requestVideoFrameCallback(onFrame);
      };

      this.videoElement.requestVideoFrameCallback(onFrame);
    } catch (err) {
      console.error('[LandmarkRecorderPlugin] No camera stream found', err);
      this.stop();
    }
  }

  private onFaceLandmarkerResults(results: FaceLandmarkerResult) {
    if (!results.faceLandmarks || !results.faceLandmarks.length || !results.faceBlendshapes || !results.faceBlendshapes.length) return;

    if (!this.infoSent) {
      this.ikonWorker.postMessage({
        command: IkonCommand.SendLandmarksInfo,
        data: {
          Category: 'Landmarks',
          Type: TrackingType.Face,
          FaceBlendshapes: results.faceBlendshapes[0].categories.map((category) => category.categoryName),
        } as TrackingStreamBegin,
      });
      this.infoSent = true;
    }

    this.ikonWorker.postMessage({
      command: IkonCommand.SendLandmarks,
      data: {
        TimestampInUs: Math.trunc(performance.now()) * 1000,
        DurationInUs: this.durationInUs,
        FaceBlendshapes: this.convertBlendshape(results.faceBlendshapes),
        FaceTransformationMatrix: results.facialTransformationMatrixes?.at(0)?.data,
      } as TrackingFrame,
    });
  }

  private convertBlendshape(blendshapes: Classifications[] | undefined) {
    if (!blendshapes) return undefined;
    return blendshapes[0].categories.map((category) => category.score);
  }
}
