import { IkonCommand } from '../ikon-command';

export class ScenePlayer {
  constructor(
    private ikonWorker: Worker,
    private canvas: HTMLCanvasElement,
    private bucketViewUrl?: string,
  ) {
    console.log('[ScenePlayer] Initialise');

    const offscreenCanvas = canvas.transferControlToOffscreen();

    ikonWorker.postMessage(
      {
        command: IkonCommand.InitializeScenePlayer,
        canvas: offscreenCanvas,
        bucketViewUrl,
      },
      { transfer: [offscreenCanvas] },
    );
  }
}
