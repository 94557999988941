import { Room, RoomTemplatePublic } from '@ikon-web/space-types';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Outlet, useParams } from 'react-router-dom';
import { Navigation } from '../../../../components/navigation';
import { featureLocalRooms } from '../../../../shared/utils/env.utils';

export function SpaceSingleLayout(props: {
  activeRoomCode: string;
  isProcessing: boolean;
  rooms: Room[];
  roomTemplates: RoomTemplatePublic[];
  onRoomCreate: (roomTemplate: RoomTemplatePublic, local?: boolean) => void;
}) {
  const { code } = useParams();

  if (code) return <Outlet />;

  return (
    <>
      <Navigation />
      <Box sx={{ mt: '64px' }}>
        <Box pt={5}>
          <Stack direction="column" spacing={2} justifyContent="center">
            <Box padding={1}>
              <Grid container spacing={2} justifyContent="center">
                {props.roomTemplates?.map((roomTemplate) => (
                  <Grid key={roomTemplate.id} xs={12} sm={6} md={3}>
                    <Button fullWidth disabled={props.isProcessing} onClick={() => props.onRoomCreate(roomTemplate, false)}>
                      Start {roomTemplate.name}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box padding={1} textAlign="center">
              {featureLocalRooms ? (
                <div className="mt-4">
                  <Typography variant="h4" mb={1}>
                    Local rooms
                  </Typography>
                  <Grid container spacing={2} justifyContent="center">
                    {props.roomTemplates?.map((roomTemplate) => (
                      <Grid key={roomTemplate.id} xs={12} sm={6} md={3}>
                        <Button fullWidth disabled={props.isProcessing} onClick={() => props.onRoomCreate(roomTemplate, true)}>
                          Start {roomTemplate.name}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              ) : null}
            </Box>
          </Stack>
          <Box sx={{ textAlign: 'center', mt: 3, mb: 3 }}>{props.isProcessing && <CircularProgress />}</Box>
        </Box>
      </Box>
    </>
  );
}
