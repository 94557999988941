export enum IkonCommand {
  Status = 'status',
  Configure = 'configure',
  Connect = 'connect',
  Close = 'close',
  Closed = 'closed',

  InitializeAudioPlayerReceiver = 'initialize-audio-player-receiver',
  InitializeVideoRenderer = 'initialize-video-renderer',
  InitializeBlobRenderer = 'initialize-blob-renderer',
  InitializeScenePlayer = 'initialize-sceneplayer',
  InitializeChatService = 'initialize-chat-service',
  InitializeUiReceiver = 'initialize-ui-receiver',
  InitializeScreenRecorderSender = 'initialize-screen-recorder-sender',

  AudioPlayerReceiverInitialized = 'audio-player-receiver-initialized',
  AudioPlayerReceiverRemoved = 'audio-player-receiver-removed',
  AudioPlayerWorkletInitialized = 'audio-player-worklet-initialized',
  AudioRecorderWorkletInitialized = 'audio-recorder-worklet-initialized',
  AudioRecorderSenderInitialized = 'audio-recorder-sender-initialized',

  StartAudioRecording = 'start-audio-recording',
  StopAudioRecording = 'stop-audio-recording',

  CloseAudioPlayerReceiver = 'close-audio-player-receiver',
  CloseAudioRecorderSender = 'close-audio-recorder-sender',
  CloseVideoRenderer = 'close-video-renderer',
  CloseBlobRenderer = 'close-blob-renderer',

  ScreenRecorderConfiguration = 'screen-recorder-configuration',
  ScreenRecorderStop = 'screen-recorder-stop',
  ScreenRecorderFrame = 'screen-recorder-frame',

  SendLandmarksInfo = 'sendLandmarksInfo',
  SendLandmarks = 'sendLandmarks',
  SendLandmarksFaceMesh = 'sendLandmarksFaceMesh',

  Stats = 'stats',
  State = 'state',

  Resize = 'resize',
  Visibility = 'visibility',

  UpdateUIElement = 'update-ui-element',
  SendPan = 'send-pan',
  SendTap = 'send-tap',
  SendZoom = 'send-zoom',

  OpenExternalUrl = 'open-external-url',
  OpenRoom = 'open-room',
  ReloadRooms = 'reload-rooms',
  ReloadProfile = 'reload-profile',

  ChatBlockingAction = 'chat-blocking-action',
  SendChatText = 'send-chat-text',
  SendChatFile = 'send-chat-file',
  CallAction = 'call-action',
  CallFileUploadAction = 'call-file-upload-action',

  UiContainer = 'ui-container',
  UiContainerRemove = 'ui-container-remove',
  UiText = 'ui-text',
  UiOpen = 'ui-open',
  UiClose = 'ui-close',
  UiClear = 'ui-clear',

  UserActive = 'user-active',
}
