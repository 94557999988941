import { Box } from '@mui/material';
import { debounce } from 'lodash-es';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Disclaimer } from '../../components/disclaimer';
import { selectChatContainerIds, selectChatContainerUpdatesCount } from '../../shared/data/container.slice';
import { ChatRootContainer } from './chat-root-container';

export function ChatContainers(props: { alignEnd: boolean; centerMessages?: boolean; hasFade?: boolean }) {
  const rootContainerIds = useSelector(selectChatContainerIds);
  const chatContainerUpdates = useSelector(selectChatContainerUpdatesCount);

  const bottomRef = useRef<HTMLDivElement>(null);
  const initialScrolling = useRef(false);

  const nativeScrollingSupported = useMemo(() => {
    return 'overflowAnchor' in document.documentElement.style;
  }, []);

  const debounceScroll = useMemo(() => {
    // This is because message id array is not updated when single message is being updated by delta frames.

    return debounce(() => {
      bottomRef.current?.scrollIntoView({ behavior: 'instant', block: 'nearest' });
      initialScrolling.current = true;
    }, 200);
  }, []);

  useEffect(() => {
    if (!rootContainerIds.length) return;

    if (!initialScrolling.current) {
      debounceScroll();
      return;
    }

    if (nativeScrollingSupported) return;

    bottomRef.current?.scrollIntoView({ behavior: 'instant', block: 'nearest' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootContainerIds, chatContainerUpdates]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarGutter: 'stable',
        maskImage: props.hasFade ? 'linear-gradient(transparent 0%, black 24px, black calc(100% - 24px), transparent 100%)' : null,
        px: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          placeContent: props.alignEnd ? 'flex-end' : 'flex-start',
          gap: 2,
          maxWidth: '700px',
          margin: '0 auto',
          py: 3,
          minHeight: 'calc(100% - 1px)',
          overflowAnchor: 'none',
        }}
      >
        {rootContainerIds.map((rootContainerId) => (
          <ChatRootContainer key={rootContainerId} containerId={rootContainerId as string} centerMessages={props.centerMessages} />
        ))}

        {rootContainerIds.length > 0 && <Disclaimer />}
      </Box>

      <Box sx={{ overflowAnchor: 'auto', height: '1px' }} ref={bottomRef}></Box>
    </Box>
  );
}
